import { Card, Select } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { LanguageContext } from "../context/LanguageContext";
import ReactCountryFlag from "react-country-flag";

export default function LanguageSelect(){

    const locale=useContext(LanguageContext);

    return(
        <Card
            sx={{
                position:'relative',
                ml:'1rem',
                height:'56px',
            }}
        >
            <ReactCountryFlag
                svg
                countryCode={locale.lang.flag}
                style={{
                    position:'absolute',
                    height:'1.5rem',
                    width:'1.5rem',
                    top:'1rem',
                    left:'0.75rem'
                }}
            />
            <Select
                native
                value={locale.lang.code}
                onChange={e=>locale.updateLang(e.target.selectedIndex)}
                sx={{
                    pl:'2rem',
                }}
            >
                {locale.langs.map((lang)=>{
                    return(
                        <option value={lang.code} key={lang.code}>
                            {lang.name}
                        </option>
                    );
                })}
            </Select>
        </Card>
    );
}