import { faArrowLeft, faArrowRight, faArrowToRight } from '@fortawesome/pro-light-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Fade, Grid, Grow, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import handleImageSnapshot from '../functions/handleImageSnapshot';
import { AreaContext } from '../store/AreaContext';
import { PLAY, SAVE_DESTINATION } from '../store/AreaReducers';
import { isMobile } from 'react-device-detect';
import { LanguageContext } from '../context/LanguageContext';

const HotspotButton = ({ background, hotspot, area, video_ref, side, canvas, isVisible }) => {

	const locale=useContext(LanguageContext);

	const theme = useTheme();
	const xlDown = useMediaQuery((theme) => theme.breakpoints.down('xl'));
	const { dispatchDestination, dispatchTransition } = useContext(AreaContext);
	//const [background_url, setBackground_url] = useState(background);
	const [background_url, setBackground_url] = useState(`${process.env.REACT_APP_API}${background}`);

	const handleClick = (hotspot, video_ref) => {
		//console.log(area)
		//console.log(canvas)
		//console.log(background_url)

		handleImageSnapshot(canvas, background_url);

		const transition_in_url = video_ref.url;
		//Save the destination area
		setTimeout(() => {
			dispatchDestination({
				type: SAVE_DESTINATION,
				payload: {
					//to: `/${hotspot.slug}`,
					to: hotspot.route,
					transition_url: transition_in_url
				}
			});
			dispatchTransition({
				type: PLAY,
				payload: {
					url: transition_in_url,
					//to: `/${hotspot.slug}`
					to: hotspot.route
				}
			});
		}, [200])
	};

	const [grow, setGrow] = useState(false)

	useEffect(() => {
		if (isVisible) {
			setTimeout(() => {
				setGrow(true)
			}, [500])
		} else {
			setGrow(false)
		}
	}, [isVisible])


	return (
		<Fade in={isVisible} timeout={{ enter: 1000, exit: 1000 }}>
			<Box
				onClick={e => handleClick(hotspot, video_ref)}
			>
				<Grow in={grow} timeout={{ enter: 1000, exit: 1000 }}>
					<Grid
						container
						item
						alignItems="center"
						sx={{ textAlign: 'center', cursor: 'pointer' }}
					>
						<Grid item xs={12}>
							<Typography
								component="p"
								variant={isMobile ? "caption" : xlDown ? "body1" : "h5"}
								sx={{
									padding: isMobile ? "1em" : xlDown ? '.75em 1em .75em .75em' : '1em 1.25em 1em  1em',
									color: theme.palette.primary.main,
									textTransform: 'uppercase'
								}}
							>
								{side==='left'?
									area.title_left
								:
									area.title_right
								}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<span className="fa-layers fa-fw fa-2x">
								<FontAwesomeIcon color={theme.palette.farm.main} icon={faCircle} />
								<FontAwesomeIcon color="white" transform={'shrink-8'} icon={side === 'left' ? faArrowLeft : faArrowRight} />
							</span>
						</Grid>
						<Grid item xs={12}>
							<Typography
								component="p"
								variant={isMobile ? "caption" : "body1"}
								sx={{
									//padding: '1em 1em 2em 1em', 
									padding: isMobile ? "1em" : xlDown ? '.75em .75em 1em .75em' : '1em 1em 1.25em 1em',
									color: theme.palette.primary.main,
									maxWidth: isMobile ? '180px' : '220px',
									margin: '0 auto'
								}}
							>
								{side==='left'?
									area.description_left
								:
									area.description_right
								}
							</Typography>
						</Grid>
					</Grid>
				</Grow>
			</Box>
		</Fade>
	);
};

export default HotspotButton;
