import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import axios from 'axios';

const TypeSelect = ({ selectedType, setSelectedType, textData }) => {

	const [types, setTypes]=useState();

	//Fetch product categories after translation data becomes available / when language changes
	useEffect(()=>{
		if(textData){
			axios.get(
				process.env.REACT_APP_API+'/api/resource-types',
				{
					params:{

					}
				}
			).then((response)=>{
				//console.log(response)
				const all=[{id:0, attributes:{name:textData.repository_type_select_all}}, ...response.data.data];
				setTypes(all);
				setSelectedType(all[0]);
			}).catch((error)=>{
				console.log(error);
			});
		}
	},[textData]);

	//console.log(types)
	//console.log(selectedType)

	return(
		<>
			{types &&
				<Autocomplete
					sx={{
						'& .MuiAutocomplete-endAdornment': {
							right: '43px!important',
							top: '39px!important'
						}
					}}
					disableClearable
					//multiple={true}
					id="selected-brands"
					value={selectedType}
					options={types}
					getOptionLabel={((option)=>{
						return option.attributes.name;
					})}
					// isOptionEqualToValue={(option, value) =>
					// 	option.id === value.id
					// }
					onChange={(event, newValue) => setSelectedType(newValue)}
					renderInput={params => (
						<TextField 
							{...params} 
							variant="outlined" 
							label={textData?.repository_type_select}
						/>
					)}
				/>
			}
		</>
	);
};

export default TypeSelect;
