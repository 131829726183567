import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import axios from 'axios';

const CategorySelect = ({ selectedCategory, setSelectedCategory, textData }) => {

	const [categories, setCategories] = useState();

	//Fetch product categories after translation data becomes available / when language changes
	useEffect(()=>{
		if(textData){
			axios.get(
				process.env.REACT_APP_API+'/api/product-categories',
				{
					params:{
						'sort':'name'
					}
				}
			).then((response)=>{
				//console.log(response)
				const all=[{id:0, attributes:{name:textData.repository_category_select_all}}, ...response.data.data];
				setCategories(all);
				setSelectedCategory(all[0]);
			}).catch((error)=>{
				console.log(error);
			});
		}
	},[textData]);

	//console.log(categories)
	//console.log(selectedCategory)

	return(
		<Autocomplete
			sx={{
				'& .MuiAutocomplete-endAdornment': {
					right: '43px!important',
					top: '39px!important'
				}
			}}
			disableClearable
			//multiple={true}
			id="selected-brands"
			value={selectedCategory}
			options={categories || []}
			getOptionLabel={((option)=>{
				return option.attributes.name;
			})}
			isOptionEqualToValue={(option, value) =>
				option.id === value.id
			}
			onChange={(event, newValue) => setSelectedCategory(newValue)}
			renderInput={params => (
				<TextField 
					{...params} 
					variant="outlined" 
					label={textData?.repository_category_select}
				/>
			)}
			loadingText="Updating Categories...."
		/>
	);
};

export default CategorySelect;
