import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FarmContext } from '../store/FarmContext';
import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Link, List, ListItem, ListItemAvatar, ListItemText, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { motion } from 'framer-motion';

import {
	card_image,
	card_text,
	icon,
} from '../styles/info_variants';
import { faTimesCircle } from '@fortawesome/pro-duotone-svg-icons';
import { isMobile } from 'react-device-detect';
import { LanguageContext } from '../context/LanguageContext';

const useStyles = makeStyles(theme => ({
	card: {
		borderRadius: 0,
		position: 'relative',
		background: '#ffffff',
		padding: theme.spacing(2),
		maxWidth: theme.spacing(90),
		zIndex: "inherit"
	},
	card_border: {
		borderRadius: 0,
		position: 'absolute',
		background: '#ffffff',
		padding: theme.spacing(2),
		maxWidth: theme.spacing(90)
	},
	card_right_size: ({ info_open }) => {
		return {
			paddingLeft: theme.spacing(2)
		};
	},
	card_image: {
		alignSelf: 'center',
	},
	circle: ({ info_open, colour }) => ({
		padding: '10px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: '50%',
		backgroundColor: colour ? colour : theme.palette.primary.main,
		position: 'absolute',
		right: '-16px',
		zIndex: "inherit",
		//zIndex: theme.zIndex.tooltip,
		transition: 'bottom ease-out 0.3s',
		//bottom: info_open ? 'calc(100% - 16px)' : '-16px',
		bottom: '-16px',
		width: '35px',
		height: '35px',
		[theme.breakpoints.down('xl')]: {
			width: '35px',
			height: '35px'
		},
		[theme.breakpoints.down('lg')]: { width: '30px', height: '30px' },
		[theme.breakpoints.down('sm')]: {
			width: '25px',
			height: '25px'
		},
		'&:hover': {
			transform: 'scale(1.3)',
			cursor: 'pointer'
		}
	}),
	circle_icon: {
		color: theme.palette.common.white,
		fontSize: '15px'

	}
}));


const HotspotInfoDialog = ({ hotspot }) => {

	const locale=useContext(LanguageContext);

	//console.log(hotspot)
	const [info_open, setInfo_open] = useState(false);
	const hotspot_info = useMemo(() => hotspot.attributes.type[0], [hotspot.attributes.type]);
	const position_x = useMemo(() => hotspot.attributes.position_x, [hotspot.attributes.position_x]);
	const position_y = useMemo(() => hotspot.attributes.position_y, [hotspot.attributes.position_y]);
	const {
		infoBox,
		dispatchInfoBox
	} = useContext(FarmContext);

	const colour = hotspot_info?.colour
	const classes = useStyles({ info_open, colour });
	const [viewFile, setViewFile] = useState(false);

	const closeViewFile = () => {
		setViewFile(false);
	};
	const openViewFile = () => {
		setViewFile(true);
	};

	const showResource = (resource) => {
		openViewFile()
	}

	const handleIcon = () => {
		setInfo_open(true)
	}

	useEffect(() => {

		if (infoBox && infoBox.id !== hotspot.id) {
			setInfo_open(false)
		}

	}, [infoBox, hotspot])

	//retrun null if not published
	if (!hotspot?.attributes?.publishedAt) return null;

	return (
		<Box sx={{
			//zIndex: info_open ? 10 : 1 ,
			// position : 'relative'
		}}>

			<motion.div
				className={classes.circle}
				variants={icon}
				whileHover="hover"
				initial="hidden"
				animate="visible"
				onClick={handleIcon}
				color={hotspot_info.colour}
			>
				{hotspot_info.tooltip?
					<Tooltip 
						arrow
						title={hotspot_info.tooltip} 
					>
						<Box>
							<FontAwesomeIcon
								icon={info_open ? faMinus : faPlus}
								className={`${classes.circle_icon}`}
							/>
						</Box>
					</Tooltip>
				:
					<FontAwesomeIcon
						icon={info_open ? faMinus : faPlus}
						className={`${classes.circle_icon}`}
					/>
				}
				{isMobile && hotspot_info?.tooltip &&
					<Typography
						variant="caption"
						sx={{
							top: '105%',
							position: 'absolute',
							whiteSpace: 'nowrap',
							color: "#fff",
							fontWeight: 700,
							textAlign: 'center',
							backgroundColor: theme => hotspot_info.colour ? hotspot_info.colour : theme.palette.primary.main,
							padding: '5px',
							borderRadius: '5px'


						}}
					>
						{hotspot_info.tooltip}
					</Typography>
				}
			</motion.div>

			<Dialog
				open={info_open}
				onClose={() => setInfo_open(false)}
			>
				<DialogTitle>
					{hotspot_info.tooltip?
						hotspot_info.tooltip
					:
						hotspot_info.title
					}
					<IconButton
						aria-label="close"
						onClick={() => setInfo_open(false)}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: theme => theme.palette.resources.main,
						}}
					>
						<FontAwesomeIcon icon={faTimesCircle} size='lg' />
					</IconButton>
				</DialogTitle>
				<DialogContent sx={{ background: theme => theme.palette.grey[100] }}>
					<Box display="grid" sx={{
						paddingTop: '1em',
					}}>
						<Grid
							item
							container
							sx={{ height: '100%', overflow: 'hidden', position: 'relative', zIndex: 2 }}
						>
							<Grid item xs={4} sx={{ position: 'relative' }}>
								<Grid
									item
									sx={{ width: '100%', height: '100%' }}
									className={classes.card_image}
									id="card_left"
									variants={card_image}
								>
									{hotspot_info?.info_media?.data ?
										<img
											style={{ height: '100%', width: '100%', objectFit: 'cover', position: 'absolute' }}
											src={`${process.env.REACT_APP_API}${hotspot_info.info_media.data.attributes.url}`}
											alt={hotspot_info.info_media.data.attributes.alternativeText}
										/>
										: null}
								</Grid>
							</Grid>
							<Grid
								item
								xs={8}
								direction="column"
								container
								className={classes.card_right_size}
								id="card_right"
							// remove the right side otherwise will prevent hover on anything below because still present although non visible
							>
								<Grid item >
									<Typography
										variant="h6"
										color="primary"
										variants={card_text}
										sx={{
											lineHeight: 1.2,
											marginBottom: '.75em'
										}}
									>
										{hotspot_info.title}
									</Typography>
									<Typography variant='caption' component='p'>
										{hotspot_info.description}
									</Typography>
								</Grid>
								{hotspot_info.external_link && (
									<Grid item>
										<Link
											component={motion.a}
											color="textPrimary"
											className={classes.card_description}
											variants={card_text}
											href={hotspot_info.external_link}
										>
											{`${hotspot_info.external_link_text} >`}
										</Link>
									</Grid>
								)}
							</Grid>

							{hotspot_info.highlights && hotspot_info.highlights.length > 0 ?
								<Grid
									item
									xs={hotspot_info?.highlights_media?.data?.attributes.url ? 8 : 12}
									direction="column"
									container
								>
									<Grid item xs={12}>
										<Typography
											component="p"
											variant="p"
											sx={{ margin: '1em 0 0 0' }}
										>
											{{
												'en':'PRODUCT HIGHLIGHTS',
												'de':'PRODUKT HÖHEPUNKTE'
											}[locale.lang.code]}
										</Typography>
										<List component={motion.ul} className={classes.list}>
											{hotspot_info.highlights.map((highlight, index) => (
												<ListItem key={index} sx={{ padding: '8px' }}>
													<ListItemAvatar>
														<Avatar sx={{ width: '30px', height: '30px' }}>{index + 1}</Avatar>
													</ListItemAvatar>
													<ListItemText>
														<Typography variant="caption" component="p" >
															{highlight.description}
														</Typography>
													</ListItemText>
												</ListItem>
											))}
										</List>
									</Grid>
								</Grid>
								: null}
							{hotspot_info?.highlights_media?.data?.attributes.url ?
								<Grid item xs={4} sx={{ position: 'relative' }}>
									<img
										style={{ height: '100%', width: '100%', objectFit: 'contain', position: 'absolute' }}
										//src={hotspot_info.highlights_media.data.attributes.url}
										src={`${process.env.REACT_APP_API}${hotspot_info?.highlights_media?.data?.attributes.url}`}
										alt={hotspot_info?.info_media.data?.attributes?.alternativeText}
									/>
								</Grid>

								: null}
						</Grid>
					</Box>
				</DialogContent>

			</Dialog>

		</Box>
	);
};

export default HotspotInfoDialog;
