import { Box, Button, Collapse, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import useWindowSize from "../functions/useWindowSize";
import { FilterContext } from "../context/FilterContext";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

export default function FilterBar({ canvas }){

    const filters=useContext(FilterContext);
	const history=useHistory();
    const location=useLocation();
    const dimensions=useWindowSize();

    const [open, setOpen]=useState(false);
    const [container, setContainer]=useState();

    useEffect(()=>{
        if(canvas.current){
            setContainer(canvas.current.getBoundingClientRect());
        }
    },[dimensions]);

    const btnStyle={
        background:'#008cba',
        '&:hover':{
            background:'#1A97C1'
        },
        color:'#fff',
        width:'100%',
        fontWeight:600,
        maxWidth:'200px',
    };

    return(
        <Box
            sx={{
                zIndex:999,
                position:'absolute',
                bottom:container?.top,
                left:container?.left,
                display:'flex',
                flexDirection:'column',
                alignItems:'center'
            }}
        >
            <Box
                onClick={()=>setOpen(!open)}
                sx={{
                    height:'2rem',
                    width:'4rem',
                    borderRadius:'2rem 2rem 0 0',
                    background:'rgba(0, 140, 186, 0.25)',
                    color:'#fff',
                    backdropFilter:'blur(0.5rem)',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    cursor:'pointer'
                }}
            >
                <ExpandLessIcon
                    sx={{
                        fontSize:'1.8rem',
                        translate:'0 2px',
                        rotate: open? '180deg' : 0,
                        transition:'rotate 0.25s'
                    }}
                />
            </Box>
            <Collapse
                in={open}
            >
                <Box
                    sx={{
                        p:'1rem 2rem',
                        background:'rgba(0, 140, 186, 0.25)',
                        backdropFilter:'blur(0.5rem)',
                        color:'#fff',
                        width:container?.width
                    }}
                >
                    <Grid container rowSpacing={0} columnSpacing={8}>
                        <Grid item xs={6} container spacing={2}
                            sx={{
                                '& .MuiGrid-item':{
                                    textAlign:'center'
                                }
                            }}
                        >
                            <Grid item xs={12}>
                                <Typography
                                    sx={{
                                        textAlign:'center',
                                        fontWeight:600
                                    }}
                                >
                                    Filters
                                </Typography>
                            </Grid>
                            {filters.categories.map((category)=>{
                                //console.log(category)
                                return(
                                    <Grid item xs={2.4}
                                        key={category.id}
                                    >
                                        <FilterBtn
                                            label={category.attributes.name}
                                            onClick={()=>filters.setCategory(filters.category===category.id? null : category.id)}
                                            active={filters.category===category.id}
                                        />
                                    </Grid>
                                );
                            })}
                            <Grid item xs={2.4}>
                                <FilterBtn
                                    label='Biorational'
                                    onClick={()=>filters.setBioRational(!filters.bioRational)}
                                    active={filters.bioRational}
                                />
                            </Grid>
                            <Grid item xs={2.4}>
                                <FilterBtn
                                    label='New'
                                    onClick={()=>filters.setIsNew(!filters.isNew)}
                                    active={filters.isNew}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={6} container spacing={2}>
                            <Grid item xs={12}>
                                <Typography
                                    sx={{
                                        textAlign:'center',
                                        fontWeight:600
                                    }}
                                >
                                    Go to
                                </Typography>
                            </Grid>
                            <Grid item xs={6}
                                sx={{
                                    textAlign:'right'
                                }}
                            >
                                <Button
                                    onClick={()=>{
                                        const segments=location.pathname.split('/');
                                        history.push('/'+segments[1]);
                                    }}
                                    disabled={location.pathname.split('/').length<3}
                                    sx={btnStyle}
                                >
                                    Crop overview
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    onClick={()=>history.push('/')}
                                    disabled={location.pathname==='/'}
                                    sx={btnStyle}
                                >
                                    Farm overview
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Collapse>
        </Box>
    );
}

function FilterBtn({ label, onClick, active }){

    return(
        <Button
            onClick={onClick}
            sx={{
                m:'0 auto',
                height:'3rem',
                width:'3rem',
                minWidth:'0 !important',
                background: active? 'green' : '#008cba',
                color:'#fff',
                borderRadius:'50%',
                '&:hover':{
                    background: active? 'green' : '#1A97C1'
                }
            }}
        >
            {label}
        </Button>
    );
}