import { faTimesCircle } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Dialog,
	DialogContent,
	Grid,
	IconButton,
	Typography,
	CircularProgress,
	Alert,
	DialogTitle,
	Skeleton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext, useEffect, useState } from 'react';
import ResourceCard from './ResourceCard';
import { isMobile } from 'react-device-detect';
import { LanguageContext } from '../../context/LanguageContext';
import axios from 'axios';
import CropSelect from './CropSelect';
import TypeSelect from './TypeSelect';
import CategorySelect from './CategorySelect';

const useStyles = makeStyles(theme => ({
	dialog: {
		'& .MuiBackdrop-root': {
			backgroundColor: 'rgba(250,250,250, 0.6)'
		},
		'& .MuiDialogContent-root': {
			padding: theme.spacing(isMobile ? 2 : 5),
			background: theme.palette.grey[100],
			'& #dialog_content': {
				position: 'relative',
				padding: theme.spacing(isMobile ? 1 : 3),
				border: isMobile ? 'none' : `3px solid ${theme.palette.common.white}`,
				'& .MuiIconButton-root': {
					position: 'absolute',
					top: -34,
					right: -34,
					color: theme.palette.resources.main,
					'& svg': {
						fontSize: '2.5rem',
						'& .fa-secondary': {
							color: theme.palette.resources.main,
							opacity: 1
						},
						'& .fa-primary': {
							color: 'white'
						}
					}
				}
			}
		}
	},
	title: {
		background: theme.palette.resources.main,
		padding: theme.spacing(2),
		color: theme.palette.common.white,
		marginBottom: theme.spacing(1),
		fontWeight: 400
	},

	subtitle: {
		fontWeight: 400
	}
}));

const ResourceDialog = ({ open, handleClose }) => {

	const locale=useContext(LanguageContext);
	const classes = useStyles();

	const [selectedCrop, setSelectedCrop] = useState(null);
	const [selectedCategory, setSelectedCategory]=useState(null);
	const [selectedType, setSelectedType] = useState(null);
	const [resources, setResources] = useState(null);
	const [filteredResources, setFilteredResources]=useState(null);

	//Fetch resources on crop and category change
	useEffect(()=>{
		if(selectedCrop && selectedCategory){
			axios.get(
				process.env.REACT_APP_API+'/api/resources',
				{
					params:{
						'filters[crop][id][$eq]':selectedCrop.id,
						'filters[product][product_category][id][$eq]':selectedCategory.id===0? null : selectedCategory.id,
					}
				}
			).then((response)=>{
				//console.log(response.data.data);
				setResources(response.data.data);
			}).catch((error)=>{
				console.log(error);
			});
		}
	},[selectedCrop, selectedCategory]);

	//Filter resources on resource type change
	useEffect(()=>{
		if(selectedType && resources){
			//console.log(selectedType)
			if(selectedType.id===0){
				setFilteredResources(resources);
				
			}
			else{
				const filtered=resources.filter((resource)=>resource.attributes.type[0].__component===selectedType.attributes.component);
				setFilteredResources(filtered);
			}
		}
	},[selectedType, resources]);

	//console.log(filteredResources)

	//Fetch text & translations
	const [textData, setTextData]=useState();
	useEffect(()=>{
		axios.get(
			process.env.REACT_APP_API+'/api/misc-text-areas',
			{
				params:{
					locale:locale.lang.code
				}
			}
		).then((response)=>{
			//console.log(response);
			if(response.data.data){
				setTextData(response.data.data.attributes);
			}
		}).catch((error)=>{
			console.log(error);
		});
	},[locale]);

	//console.log(textData)

	return(
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="resources_dialog"
			className={classes.dialog}
			fullWidth
			maxWidth="xl"
		>
			{isMobile &&
				<DialogTitle>
					{textData? textData.repository_heading : <Skeleton/>}
					{handleClose?
						<IconButton
							aria-label="close"
							onClick={handleClose}
							sx={{
								position: 'absolute',
								right: 8,
								top: 8,
								color: theme => theme.palette.resources.main,
							}}
						>
							<FontAwesomeIcon icon={faTimesCircle} size='xl' />
						</IconButton>
					: 
						null
					}
				</DialogTitle>
			}
			<DialogContent>
				<Grid
					container
					spacing={1}
					alignItems="center"
					direction="column"
					id="dialog_content"
				>
					{!isMobile ?
						<>
							<IconButton
								aria-label="close_dialog"
								onClick={handleClose}
								className={classes.closeIcon}
								col="resources"
							>
								<FontAwesomeIcon icon={faTimesCircle} />
							</IconButton>
							<Grid container item >
								<Grid item xs={12} textAlign="center" >
									<Typography variant={isMobile ? 'p' : 'h6'}
										sx={{
											background: theme => theme.palette.resources.main,
											padding: theme => theme.spacing(2),
											color: theme => theme.palette.common.white,
											margin: '0  auto 1rem auto',
											fontWeight: 400,
											width: 'fit-content'
										}}
									>
										{textData? textData.repository_heading : <Skeleton/>}
									</Typography>
								</Grid>
								<Grid item xs={12} textAlign="center" >
									<Typography variant={isMobile ? 'p' : 'h6'} sx={{ marginBottom: '1em' }}>
										{textData? textData.repository_subheading : <Skeleton/>}
									</Typography>
								</Grid>
							</Grid>
						</>
						: null}
					<Grid container spacing={2} item>
						<Grid item xs={4}>
							<CropSelect
								selectedCrop={selectedCrop}
								setSelectedCrop={setSelectedCrop}
								textData={textData}
							/>
						</Grid>
						<Grid item xs={4}>
							<CategorySelect
								selectedCategory={selectedCategory}
								setSelectedCategory={setSelectedCategory}
								textData={textData}
							/>
						</Grid>
						<Grid item xs={4}>
							<TypeSelect
								selectedType={selectedType}
								setSelectedType={setSelectedType}
								textData={textData}
							/>
						</Grid>
					</Grid>
					{filteredResources?
						filteredResources.length?
							<Grid container item spacing={2} 
								sx={{
									marginTop:'0.5rem',
									maxHeight: isMobile ? 'auto' : '600px',
									overflowX: 'auto',
									padding:'1rem'
								}}
							>
								{filteredResources.map((resource)=>{
									if(resource.attributes.in_resources){
										return(
											<ResourceCard
												resource={resource.attributes}
												key={resource.id}
											/>
										);
									}
								})}
							</Grid>
						:
							<Grid item sx={{mt:'1rem'}}>
								<Alert severity="info">
									{textData?.repository_nonefound}
								</Alert>
							</Grid>
					:
						<CircularProgress/>
					}
				</Grid>
			</DialogContent>
		</Dialog >
	);
};

export default ResourceDialog;
