
import {
	Avatar,
	Box,
	Button,
	Grid,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { motion } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import { card_image, info_highlights } from '../styles/info_variants';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ViewFileDialog from './Resources/ViewFileDialog';
import { axiosRequest } from '../functions/axiosRequest';
import { useQuery } from 'react-query';
import { filter } from 'lodash';
import { FilterSharp } from '@mui/icons-material';
import { LanguageContext } from '../context/LanguageContext';

const useStyles = makeStyles(theme => ({
	root: ({ highlights_height, position_y }) => {

		const base_style = {
			position: 'absolute',
			padding: theme.spacing(2),
			paddingTop: theme.spacing(3),
			backgroundColor: 'rgba(0,0,0, 0.8)',
			maxWidth: theme.spacing(90),
			zIndex: 1
		};
		if (position_y > 40)
			return {
				...base_style,
				top: `-${highlights_height}px`,
				paddingTop: theme.spacing(2),
				paddingBottom: theme.spacing(3)
			};
		return base_style;
	},
	arrow: ({ position_y }) => {
		const base_style = {
			position: 'absolute',
			top: '-15px',
			left: '50%',
			transform: 'translateX(-50%)',

		};
		if (position_y > 40)
			return { ...base_style, top: 'auto', bottom: '-20px' };
		return base_style;
	},
	list_container: { paddingLeft: theme.spacing(2) },
	list: { flex: 1, padding: `0 0 ${theme.spacing(2)} 0` },
	list_item: { padding: `0 0 ${theme.spacing(2)} 0` },
	list_item_avatar: {
		marginRight: theme.spacing(1),
		minWidth: 0
	},
	list_bullet: {
		color: theme.palette.primary.main,
		background: theme.palette.common.white,
		[theme.breakpoints.down('lg')]: {
			width: '35px',
			height: '35px'
		},
		[theme.breakpoints.down('md')]: {
			width: '30px',
			height: '30px'
		}
	},
	list_text: {
		color: theme.palette.common.white
	}
}));

const HotspotInfoHighlights = ({ hotspot_info, position_x, position_y }) => {

	const locale=useContext(LanguageContext);

	const [viewFile, setViewFile] = useState(false);
	const [id, setId] = useState();
	const [resource, setResource] = useState();

	const closeViewFile = () => {
		setViewFile(false);
	};
	const openViewFile = () => {
		setViewFile(true);
	};

	// const { status, data, error, isFetching, isLoading } = useQuery(['resourceId', id],
	//   () => axiosRequest({
	//     method: 'get',
	//     endpoint: `resources/${id}`
	//   }), {
	//   enabled: !!id,
	//   notifyOnChangeProps: "tracked", //fecthes evertime the querykeys update
	//   retry: 1
	// })

	const showResource = (resource) => {
		//link
		if (resource.attributes.type[0].__component === 'resource.link') {
			//open link in new tab]
			window.open(resource.attributes.type[0].URL, '_blank')
		} else {
			//video, pdf or image
			setResource(resource.attributes)
			openViewFile()
		}
	}

	// useEffect(() => {
	//   if (data) {
	//     console.log(data)
	//     setResource(data.data.attributes)
	//     openViewFile()
	//   }
	// }, [data])

	const classes = useStyles({
		position_x,
		position_y
	});

	return (
		<Grid
			container
			className={classes.root}
			component={motion.div}
			custom={{ position_y }}
			variants={info_highlights}
			initial="hidden"
			animate="visible"
			exit="hidden"
		>
			<Box className={classes.arrow} >
				<PlayArrowIcon
					fontSize="large"
					sx={{
						color: 'white',
						transform: position_y > 40 ? 'rotate(270deg)' : 'rotate(90deg)'
					}}
				/>
			</Box>
			{/*// ***************** HIGHLIGHTS - TITLE ******************/}
			<Grid item xs={12}>
				<Typography
					variant="h5"
					sx={{ color: 'white', marginBottom: '1em' }}
					align="center"
					component={motion.h5}
					gutterBottom
				>
					{{
						'en':'PRODUCT HIGHLIGHTS',
						'de':'PRODUKT HÖHEPUNKTE'
					}[locale.lang.code]}
				</Typography>
			</Grid>
			{/*// ***************** HIGHLIGHTS - IMAGE ******************/}
			{hotspot_info.highlights_media.data &&
				<Grid item xs={4} sx={{ position: 'relative' }}>
					<img
						style={{ height: '100%', width: '100%', objectFit: 'cover', position: 'absolute' }}
						//src={hotspot_info.highlights_media.data.attributes.url}
						src={`${process.env.REACT_APP_API}${hotspot_info.highlights_media.data.attributes.url}`}
						alt={hotspot_info.info_media.data.attributes.alternativeText}
					/>
				</Grid>
			}
			{/*// ***************** HIGHLIGHTS - LIST CONTAINER ******************/}
			<Grid
				item
				xs={8}
				direction="column"
				container
				className={classes.list_container}
			>
				{/*// ***************** HIGHLIGHTS - LIST ******************/}
				<List component={motion.ul} className={classes.list}>
					{hotspot_info.highlights.map((highlight, index) => (
						<ListItem
							component={motion.li}
							key={highlight.id}
							className={classes.list_item}
						>
							<ListItemAvatar className={classes.list_item_avatar}>
								<Avatar className={classes.list_bullet}>{index + 1}</Avatar>
							</ListItemAvatar>

							<ListItemText>
								<Typography variant="body1" className={classes.list_text}>
									{highlight.description}
								</Typography>
							</ListItemText>
						</ListItem>
					))}
				</List>
				{/*// ***************** HIGHLIGHTS - BUTTONS ******************/}
				{hotspot_info.resources && (
					<Grid item container spacing={1}>
						{hotspot_info.resources.data.map(resource => {

							return (
								<Grid item key={resource.id}>
									{/* <Link
                    href={`${process.env.REACT_APP_API}${resource.file[0].url}`}
                    target="_blank"
                  > */}
									<Button
										variant="contained"
										fullWidth
										style={{
											background: resource.attributes.button_color,
											color: 'white',
											fontWeight: 600,
											borderRadius: 0,
											padding: '1em'
										}}
										onClick={e => showResource(resource)}
									>
										{resource.attributes.button_text}
									</Button>
									{/* </Link> */}
								</Grid>
							);
						})}
					</Grid>
				)}
			</Grid>
			{viewFile && (
				<ViewFileDialog
					closeViewFile={closeViewFile}
					resource={resource}
					viewFile={viewFile}
				/>
			)}
		</Grid>

	);
};

export default HotspotInfoHighlights;
