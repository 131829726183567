import { Box, Fade } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import HotspotInfo from './Hotspot_info';
import HotspotInfoBox from './Hotspot_info_box';
import HotspotNavigator from './Hotspot_navigator';
import { isMobile } from 'react-device-detect';
import HotspotInfoDialog from './Hotspot_info_dialog';
import { FilterContext } from '../context/FilterContext';

const useStyles = makeStyles(theme => ({
	root: ({ position_x, position_y }) => {
		const base_style = {
			position: 'absolute',
			top: `${position_y}%`,
			zIndex: "inherit",
			// zIndex: 2,
			//zIndex: 1005
		};
		if (position_x < 50)
			return {
				...base_style,
				left: `${position_x}%`,
				marginRight: theme.spacing(2)
			};
		return {
			...base_style,
			// Value coming from CMS is based on starting from left, so we if more than 50%, right value is 100% - position_x
			right: `${100 - position_x}%`,
			marginLeft: theme.spacing(2)
		};
	}
}));

const Hotspot = ({ hotspot, video_ref, area, canvas }) => {

	//console.log(hotspot)

	const filters=useContext(FilterContext);

	const [show, setShow]=useState(false);

	useEffect(()=>{
		//Conditionally display product info hotspot depending on filters
		if(hotspot.attributes.type[0].product && hotspot.attributes.type[0].product.data){
			const product=hotspot.attributes.type[0].product.data.attributes;
			//console.log(product)
			if((filters.category===null || filters.category===product.product_category.data?.id) && (product.biorational===filters.bioRational && product.new===filters.isNew)){
				setShow(true);
			}
			else{
				setShow(false);
			}
		}
		//Info hotspots not assigned to a product always show
		else{
			setShow(true);
		}
	},[filters]);

	const classes = useStyles({
		position_x: hotspot.attributes.position_x,
		position_y: hotspot.attributes.position_y
	});

	const type = useMemo(() => {
		//console.log(hotspot)
		return hotspot.attributes.type[0].__component.split('.')[1]
	}, [hotspot]);

	if (type === 'navigator')
		return (
			<Fade in={true} timeout={{ enter: 500, exit: 500 }}>
				<Box className={classes.root} >
					<HotspotNavigator
						// hotspot_styles={classes.root}
						hotspot={hotspot}
						video_ref={video_ref}
						area={area}
						canvas={canvas}
					/>
				</Box>
			</Fade>
		);
	if (type === 'info')
		return (
			<Fade in={show} timeout={{ enter: 500, exit: 500 }}>
				<Box className={classes.root}>
					{isMobile ?
						<HotspotInfoDialog hotspot={hotspot} />
						:
						<HotspotInfo hotspot={hotspot} />
					}
				</Box>
			</Fade>
		);
	if (type === 'info-box')
		return (
			<Fade in={true} timeout={{ enter: 500, exit: 500 }}>
				<Box className={classes.root}>
					<HotspotInfoBox hotspot={hotspot} />
				</Box>
			</Fade>
		);
};

export default Hotspot;
