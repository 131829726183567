const handleVideoSnapshot = ({ canvas_ref, video_ref }) => {


  //console.log(canvas_ref)
  //console.log(video_ref)

  const main_canvas = canvas_ref.current.getContext('2d');
  const aspectRatio = 1920/1080;
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  const windowAspectRatio = windowWidth / windowHeight;

  let imageWidth, imageHeight
  if (windowAspectRatio < aspectRatio) {

    imageWidth = windowWidth;
    imageHeight = windowWidth / aspectRatio;

  } else {
    imageWidth = windowHeight * aspectRatio;
    imageHeight = windowHeight;
  }
  main_canvas.drawImage(video_ref.current, 0, 0, imageWidth, imageHeight);
};

 export default handleVideoSnapshot;


// const handleVideoSnapshot = ({ canvas_ref, video_ref }) => {
//   //const canvas = canvas_ref.current;
//   const canvas_context = canvas_ref.current.getContext('2d');
//   // scale the canvas accordingly

//   // removed fixed the video weird resize???????????

//   //canvas.width = video_ref.current.videoWidth;
//   //canvas.height = video_ref.current.videoHeight;

//     //canvas_context.drawImage(video_ref.current, 0, 0, 1024, 1024);
//     canvas_context.drawImage(video_ref.current, 0, 0, 1920, 1080);



// };

// export default handleVideoSnapshot;
