import React, { createContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";

export const LanguageContext=createContext();

export function LanguageProvider(props){

    const langs=[
        {
            name:'EN',
            code:'en',
            flag:'GB',
        },
        {
            name:'DE',
            code:'de',
            flag:'DE'
        }
    ];

    const [cookies, setCookie, removeCookie]=useCookies();
    const [lang, setLang]=useState(cookies.lang? langs.find(lang=>lang.code===cookies.lang) : langs[0]);

    //Initialise cookie if not present
    useEffect(()=>{
        if(!cookies.lang){
            setCookie('lang', langs[0].code);
        }
    },[]);

    //On change
    function updateLang(index){
        setLang(langs[index]);
        setCookie('lang', langs[index].code);
    }

    //console.log(lang)

    return(
        <LanguageContext.Provider value={{ langs, lang, updateLang }}>
            {props.children}
        </LanguageContext.Provider>
    );
};