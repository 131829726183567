import { Dialog, DialogTitle, Grid, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useMemo } from 'react';
import ViewPDF from './ViewPDF';
import { faTimesCircle } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(theme => ({
  viewDialog: {
    backgroundColor: 'rgba(0,0,0, 0.6)'
  }
}));

const ViewFileDialog = ({ closeViewFile, resource, viewFile }) => {
  // ****************************** STYLES ******************************
  const classes = useStyles();
  // ****************************** USE STATES ******************************

  // ****************************** USE MEMO ******************************

  //console.log(resource)
  const { __component: type } = useMemo(() => resource.type[0],
    [resource.type]
  );

  const media_url = useMemo(() => {
    if (resource.type[0].__component !== 'resource.link') {
      return `${process.env.REACT_APP_API}${resource.type[0].media.data.attributes.url}`;
    } else {
      return `${process.env.REACT_APP_API}${resource.type[0].URL}`;
    }
  }, [resource.type]);

  //const media_url = useMemo(() => resource.type[0].media.data.attributes.url,[resource.type]);
  // ****************************** FUNCTIONS ******************************

  //console.log(media_url);
  return (
    <Dialog
      open={viewFile}
      onClose={closeViewFile}
      aria-labelledby="resources_dialog"
      className={classes.viewDialog}
      maxWidth="lg"
      fullScreen={type === 'resource.video' ? false : true}
      sx={{ margin: type === 'resource.video' ? 0 : '10%' }}
    >
      <DialogTitle className={classes.title}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>{resource.name}</Grid>
          <Grid item>
            <IconButton
              aria-label="close_dialog"
              onClick={closeViewFile}
              color='secondary'
              sx={{
                fontSize: ' 2.5rem',
                '.fa-secondary' : {
                  color: '#F88F11',
                  opacity: 1
                },
                '.fa-primary' : {
                  color: '#FFF',
                },
                opacity: 1
              }}
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      {type === 'resource.file' ? (
        <ViewPDF url={media_url} />
      ) : type === 'resource.video' ? (
        <video
          src={media_url}
          width="100%"
          height="auto"
          controls="controls"
        // autoPlay={true}
        />
      ) : type === 'resource.image' ? (
        <img src={media_url} alt="efs" />
      ) : type === 'resource.link' ? (
        <div>link</div>
      ) : type === 'resource.content' ? (
        <div>content</div>
      ) : null}
    </Dialog>
  );
};

export default ViewFileDialog;
