import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { LanguageContext } from "./LanguageContext";

export const FilterContext=createContext();

export function FilterProvider(props){

	const locale=useContext(LanguageContext);
    //console.log(locale)

    const [categories, setCategories]=useState();
    const [category, setCategory]=useState(null);
    const [bioRational, setBioRational]=useState(false);
    const [isNew, setIsNew]=useState(false);

    //Fetch product categories
    useEffect(()=>{
        axios.get(
            process.env.REACT_APP_API+'/api/product-categories',
            {
                params:{
                    locale:locale.lang.code,
                }
            }
        ).then((response)=>{
            setCategories(response.data.data);
        }).catch((error)=>{
            console.log(error);
        });
    },[locale]);

    return(
        <FilterContext.Provider 
            value={{ 
                categories,
                category,
                setCategory,
                bioRational,
                setBioRational,
                isNew,
                setIsNew
            }}
        >
            {categories?
                props.children
            :
                null
            }
        </FilterContext.Provider>
    );
};