import React from 'react';
import { useState } from 'react';
//import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
//import { Document, Page } from 'react-pdf';
import {
  Chip,
  DialogActions,
  Grid,
  IconButton
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useRef } from 'react';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0
  },
  page: {
    '& .react-pdf__Page__canvas': {
      width: '100%',
      height: '100%'
    }
  },
  pages: {
    position: 'sticky',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 1000
  },
  documentButton: {
    background: 'rgba(250, 250, 250, 0.6)',
    '& > *': {
      pointerEvents: 'none'
    }
  },
  prevPage: { cursor: 'pointer' },
  nextPage: { cursor: 'pointer' }
}));

const ViewPDF = ({ url }) => {
  // ****************************** STYLES ******************************
  const classes = useStyles();
  // ****************************** USE STATES ******************************
  const [pdfPages, setPdfPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [scale, setScale] = useState(1);
  // const [changePageHover, setChangePageHover] = useState(false);
  // const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const canvasRef = useRef(null);

  // ****************************** USE MEMO ******************************

  // ****************************** FUNCTIONS ******************************
  const onDocumentLoadSuccess = props => {
    setPdfPages(props.numPages);
  };

  const handlePageChange = e => {
    e.persist();

    const value = e.target.id;
    //console.log(value);

    setCurrentPage(state => {
      if (value === 'prevPage') {
        if (state === 1) return pdfPages;
        return state - 1;
      }
      if (state === 15) return 1;
      return state + 1;
    });
  };
  //console.log(scale);
  const handleZoom = e => {
    e.persist();

    const value = e.target.id;

    setScale(state => {
      const fixedNumber = state.toFixed(1);
      //console.log(fixedNumber);
      if (value === 'zoomOut') {
        if (fixedNumber === '1.0') return 1;
        return state - 0.2;
      }
      if (fixedNumber === '2.0') return 2;
      return state + 0.2;
    });
  };
  return (
    <>
    {/* 
      <DialogActions className={classes.pages}>
        <Grid container justify="space-evenly" alignItems="center" spacing={1}>
          <Grid
            item
            xs={6}
            container
            justify="center"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <IconButton
                onClick={handlePageChange}
                id="prevPage"
                className={classes.documentButton}
              >
                <ChevronLeftIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Chip label={`Page ${currentPage}/${pdfPages}`} color="primary" />
            </Grid>
            <Grid item>
              <IconButton
                onClick={handlePageChange}
                id="nextPage"
                className={classes.documentButton}
              >
                <ChevronRightIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid
            item
            xs={6}
            container
            justify="center"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <IconButton
                onClick={handleZoom}
                id="zoomOut"
                className={classes.documentButton}
                disabled={scale === 1}
              >
                <RemoveIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Chip label={'Zoom'} color="primary" />
            </Grid>
            <Grid item>
              <IconButton
                onClick={handleZoom}
                id="zoomIn"
                className={classes.documentButton}
                disabled={scale === 2}
              >
                <AddIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </DialogActions> */}
        <object
            data={url}
            type="application/pdf"
            width="100%"
            height="100%"
          >
          </object>
      {/* <Document
        file={`${process.env.REACT_APP_API}${url}`}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page
          pageNumber={currentPage}
          canvasRef={canvasRef}
          className={classes.page}
          scale={scale}
        />
      </Document> */}
    </>
  );
};

export default ViewPDF;
