import React, { useContext, useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useQuery } from 'react-query';
import { axiosRequest } from '../../functions/axiosRequest';
import { isMobile } from 'react-device-detect';
import { LanguageContext } from '../../context/LanguageContext';

const CropSelect = ({ selectedCrop, setSelectedCrop, textData }) => {

	const locale=useContext(LanguageContext);

	const [categories, setCategories] = useState();

	const { data } = useQuery(['crops', locale],
		() => axiosRequest({
			method: 'get',
			endpoint: 'categories',
			params:{
				'locale':locale.lang.code
			}
		}), {
		retry: 1
	});

	//console.log(data)

	useEffect(() => {
		if (data) {
			setCategories(data.data)
			setSelectedCrop(data.data[0])
		}
	}, [data])

	//console.log(categories)
	//console.log(selectedCrop)

	return (

		<Autocomplete
			sx={{
				'& .MuiAutocomplete-endAdornment': {
					right: '43px!important',
					top: '39px!important'
				}
			}}
			disableClearable
			//multiple={true}
			id="selected-brands"
			value={selectedCrop}
			options={categories || []}
			getOptionLabel={((option)=>{
				return option.attributes.title;
			})}
			isOptionEqualToValue={(option, value) =>
				option.id === value.id
			}
			onChange={(event, newValue) => setSelectedCrop(newValue)}
			renderInput={params => (
				<TextField 
					{...params} 
					variant="outlined" 
					label={textData?.repository_crop_select}
				/>
			)}
			loadingText="Updating Categories...."

		/>
	);
};

export default CropSelect;
