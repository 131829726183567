import React, { useContext } from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import CardTypeIcon from './CardTypeIcon';
import ViewFileDialog from './ViewFileDialog';
import { isMobile } from 'react-device-detect';
import { LanguageContext } from '../../context/LanguageContext';

const useStyles = makeStyles(theme => ({
	media_container: {
		height: '100%',
		flexDirection: 'column',
		'& a': {
			position: 'relative',
			height: isMobile ? 100 : 350
		},
		'& svg': {
			fontSize: '3rem',
			color: 'white'
		},
		'& .media_icon': {
			top: '50%',
			left: '50%',
			position: 'absolute',
			transform: 'translate(-50%, -50%)'
		},
		'& .media': {
			height: '100%',
			position: 'relative',
			cursor: 'pointer',
			display: 'flex',
			'&__dropshadow': {
				background: 'rgba(0,0,0,0.6)',
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				borderRadius: 10,
				height: '100%'
			},
			'&__image': {
				height: 250,
				width: '100%',
				objectFit: 'cover',
				borderRadius: 10,
				[theme.breakpoints.down('md')]: {
					height: 180
				},
				[theme.breakpoints.down('sm')]: {
					height: 150
				}
			}
		}
	}
}));

const ResourceCard = ({ resource }) => {

	const locale=useContext(LanguageContext);

	// ****************************** STYLES ******************************
	const classes = useStyles();
	// ****************************** USE STATE ******************************
	const [isHover, setIsHover] = useState(false);
	const [viewFile, setViewFile] = useState(false);
	// ****************************** USE MEMO ******************************

	// ****************************** FUNCTIONS ******************************
	const closeViewFile = () => {
		setViewFile(false);
	};
	const openViewFile = () => {
		setViewFile(true);
	};

	return (
		<>
			{viewFile && (
				<ViewFileDialog
					closeViewFile={closeViewFile}
					resource={resource}
					viewFile={viewFile}
				/>
			)}
			<Grid
				item
				container
				xs={3}
				md={3}
				lg={2}
				className={classes.media_container}
			>
				<Grid
					className="media"
					onMouseEnter={() => setIsHover(true)}
					onMouseLeave={() => setIsHover(false)}
					onClick={openViewFile}
				>
					{resource.preview_image.data ?
						<img
							//src={resource.preview_image.data.attributes.url}
							src={`${process.env.REACT_APP_API}${resource.preview_image.data.attributes.url}`}
							//src={`http://localhost:1337${resource.preview_image.url}`}
							alt={resource.name}
							className="media__image"
						/>
						: <div className="media__image"></div>
					}
					<AnimatePresence>
						<motion.div
							className="media__dropshadow"
							initial={{ opacity: 0 }}
							animate={{ opacity: .8 }}
							exit={{ opacity: 0 }}
						/>
					</AnimatePresence>

					<CardTypeIcon type={resource.type[0]} />
				</Grid>

				<Grid item textAlign="center" sx={{ marginTop: '10px' }}>
					<Typography textAlign='center' variant="p" color="primary">
						{resource.name}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="caption" color="textPrimary">
						{resource.description}
					</Typography>
				</Grid>
			</Grid>
		</>
	);
};

export default ResourceCard;
