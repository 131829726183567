import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useContext, useState } from 'react';
import { isMobile } from 'react-device-detect';
import {
	card_text,
	icon,
	info_card
} from '../styles/info_variants';
import ReactMarkdown from 'react-markdown'
import { LanguageContext } from '../context/LanguageContext';

const useStyles = makeStyles(theme => ({
	card: {
		borderRadius: 0,
		position: 'relative',
		background: '#ffffff',
		padding: theme.spacing(isMobile ? 2 : 4),
		maxWidth: theme.spacing(90),
		//zIndex: 2
		zIndex: "inherit"
	},
	card_border: {
		borderRadius: 0,
		position: 'absolute',
		background: '#ffffff',
		padding: theme.spacing(isMobile ? 1 : 2),
		maxWidth: theme.spacing(90)
	},
	card_right_size: ({ info_open }) => {
		return {
			paddingLeft: theme.spacing(isMobile ? 1 : 2)
		};
	},
	card_image: {
		alignSelf: 'center',
	},
	circle: ({ info_open }) => ({
		padding: '10px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: '50%',
		backgroundColor: theme.palette.primary.main,
		position: 'absolute',
		right: '-16px',
		zIndex: "inherit",
		//zIndex: theme.zIndex.tooltip,
		transition: 'bottom ease-out 0.3s',
		//bottom: info_open ? 'calc(100% - 16px)' : '-16px',
		top: '-16px',
		[theme.breakpoints.down('xl')]: {
			width: '35px',
			height: '35px'
		},
		[theme.breakpoints.down('lg')]: { width: '30px', height: '30px' },
		[theme.breakpoints.down('sm')]: {
			width: '25px',
			height: '25px'
		},
		'&:hover': {
			transform: 'scale(1.3)',
			cursor: 'pointer'
		}
	}),
	circle_icon: {
		color: theme.palette.common.white,
		fontSize: '15px'

	}
}));

const HotspotInfoBox = ({ hotspot }) => {

	const locale = useContext(LanguageContext);

	//console.log(hotspot)
	const [info_open, setInfo_open] = useState(hotspot.attributes.type[0].open);
	const classes = useStyles({ info_open });
	const handleIcon = () => setInfo_open(prevState => !prevState);

	//retrun null if not published
	if (!hotspot?.attributes?.publishedAt) return null;

	return (
		<>
			<Grid
				className={classes.card}
				container
				component={motion.div}
				variants={info_card}
				initial="hidden"
				animate={info_open ? 'visible' : 'hidden'}
			>
				{/* ***************** CARD - ICON ***************** */}
				<motion.div
					className={classes.circle}
					variants={icon}
					whileHover="hover"
					initial="hidden"
					animate="visible"
					onClick={handleIcon}
				>
					<FontAwesomeIcon
						icon={info_open ? faMinus : faPlus}
						className={`${classes.circle_icon}`}
					/>
				</motion.div>
				<Grid
					item
					container
					//direction={position_x > 50 ? 'row-reverse' : 'row'}
					component={motion.div}
					alignItems="stretch"
					sx={{ height: '100%', overflow: 'hidden', position: 'relative', zIndex: 2 }}
					textAlign="center"
				>
					<Typography
						color="primary"
						component={motion.p}
						variant={isMobile ? 'p' : 'h6'}
						variants={card_text}
						sx={{
							width: '100%',
							lineHeight: 1.2,
							marginBottom: '1em'
						}}
					>
						{hotspot.attributes.type[0].title}
					</Typography>

					<Typography
						component={motion.p}
						//variant={isMobile ? 'caption' : 'h6'}
						variants={card_text}
					>
						<ReactMarkdown>
							{hotspot.attributes.type[0].description}
						</ReactMarkdown>
					</Typography>
				</Grid>
			</Grid >
		</>
	);
};

export default HotspotInfoBox;
