import { faBooks } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useState } from 'react';
import { fab_base_style, top_right_style } from '../styles/floating_button';
import ResourceDialog from './Resources/ResourceDialog';

const useStyles = makeStyles(theme => ({
	open_button: {
		background: theme.palette.resources.main,
		'&:hover': {
			background: theme.palette.resources.dark,
			cursor: 'pointer'
		}
	}
}));

const FloatingResources = ({ fixed }) => {
	// ****************************** STYLES ******************************
	const classes = useStyles();
	const fab_base = fab_base_style({ fixed });
	const top_right = top_right_style();
	// ****************************** USE STATES ******************************
	const [open, setOpen] = useState(false);

	// ****************************** FUNCTIONS ******************************
	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<Avatar
				className={`${fab_base.root} ${top_right.root} ${classes.open_button}`}
				onClick={handleOpen}
			>
				<FontAwesomeIcon icon={faBooks} size="lg" />
			</Avatar>
			<ResourceDialog open={open} handleClose={handleClose}/>
		</>
	);
};

export default FloatingResources;
